export interface ICountry {
  label: string;
  locale: string;
  link: string;
  analytics_title: string;
  isRTL?: boolean;
}
export interface IRegion {
  name: string;
  label: string;
  first?: boolean;
  countries: Array<ICountry>;
}
export const REGIONS: Array<IRegion> = [
  {
    name: 'emeia',
    label: 'Africa, Middle East, and India',
    countries: [
      { label: 'Botswana', locale: 'en-BW', link: '/bw/en/', analytics_title: 'botswana-english' },
      { label: 'البحرين', locale: 'ar-BH', link: '/bh/ar/', analytics_title: 'bahrain-arabic' },
      { label: 'Bahrain', locale: 'en-BH', link: '/bh/en/', analytics_title: 'bahrain-english' },
      { label: 'Cameroun', locale: 'fr-CM', link: '/cm/fr/', analytics_title: 'cameroun-french' },
      { label: 'Centre Afrique', locale: 'fr-CF', link: '/cf/fr/', analytics_title: 'cameroun-french' },
      { label: "Côte d'Ivoire", locale: 'fr-CF', link: '/ci/fr/', analytics_title: 'côtedivoire-french' },
      { label: 'مصر', locale: 'ar-EG', link: '/eg/ar/', analytics_title: 'egypt-arabic' },
      { label: 'Egypt', locale: 'en-EG', link: '/eg/en/', analytics_title: 'egypt-english' },
      { label: 'Guinea-Bissau', locale: 'en-GW', link: '/gw/en/', analytics_title: 'guinea-bissau-english' },
      { label: 'Guinée', locale: 'fr-GN', link: '/gn/fr/', analytics_title: 'guinée-french' },
      { label: 'Guinée Equatoriale', locale: 'fr-GQ', link: '/gq/fr/', analytics_title: 'Guinée Equatoriale-french' },
      { label: 'India', locale: 'en-IN', link: '/in/en/', analytics_title: 'india-english' },
      { label: 'Israel', locale: 'en-IL', link: '/il/en/', analytics_title: 'israel-english' },
      { label: 'الأردن', locale: 'ar-JO', link: '/jo/ar/', analytics_title: 'Jordan-arabic' },
      { label: 'Jordan', locale: 'en-JO', link: '/jo/en/', analytics_title: 'Jordan-english' },
      { label: 'Kenya', locale: 'en-KE', link: '/ke/en/', analytics_title: 'Kenya-english' },
      { label: 'الكويت', locale: 'ar-KW', link: '/kw/ar/', analytics_title: 'Kuwait-arabic' },
      { label: 'Kuwait', locale: 'en-KW', link: '/kw/en/', analytics_title: 'Kuwait-english' },
      { label: 'La Réunion', locale: 'fr-RE', link: '/re/fr/', analytics_title: 'La Réunion-english' },
      { label: 'لبنان', locale: 'ar-LB', link: '/lb/ar/', analytics_title: 'Lebanon-arabic' },
      { label: 'Lebanon', locale: 'en-LB', link: '/lb/en/', analytics_title: 'Lebanon-english' },
      { label: 'Libya', locale: 'en-LY', link: '/ly/en/', analytics_title: 'Libya-english' },
      { label: 'Madagascar', locale: 'fr-MG', link: '/mg/fr/', analytics_title: 'Madagascar-french' },
      { label: 'Mali', locale: 'fr-ML', link: '/ml/fr/', analytics_title: 'Mali-french' },
      { label: 'Maroc', locale: 'fr-MA', link: '/ma/fr/', analytics_title: 'Maroc-french' },
      { label: 'Maurice', locale: 'fr-MU', link: '/mu/fr/', analytics_title: 'Maurice-french' },
      { label: 'Mayotte', locale: 'fr-YT', link: '/yt/fr/', analytics_title: 'Mayotte-french' },
      { label: 'Mozambique', locale: 'en-MZ', link: '/mz/en/', analytics_title: 'Mozambique-english' },
      { label: 'Niger', locale: 'fr-NE', link: '/ne/fr/', analytics_title: 'Niger-french' },
      { label: 'Nigeria', locale: 'en-NG', link: '/ng/en/', analytics_title: 'Nigeria-english' },
      { label: 'عُمان', locale: 'ar-OM', link: '/om/ar/', analytics_title: 'Oman-arabic' },
      { label: 'Oman', locale: 'en-OM', link: '/om/en/', analytics_title: 'Oman-english' },
      { label: 'قطر', locale: 'ar-QA', link: '/qa/ar/', analytics_title: 'Qatar-arabic' },
      { label: 'Qatar', locale: 'en-QA', link: '/qa/en/', analytics_title: 'Qatar-english' },
      { label: 'المملكة العربية السعودية', locale: 'ar-SA', link: '/sa/ar/', analytics_title: 'Saudi Arabia-arabic' },
      { label: 'Saudi Arabia', locale: 'en-SA', link: '/sa/en/', analytics_title: 'Saudi Arabia-english' },
      { label: 'Sénégal', locale: 'fr-SN', link: '/sn/fr/', analytics_title: 'Sénégal-french' },
      { label: 'South Africa', locale: 'en-ZA', link: '/za/en/', analytics_title: 'South Africa-english' },
      { label: 'Tanzania', locale: 'en-TZ', link: '/tz/en/', analytics_title: 'Tunisie-english' },
      { label: 'Tunisie', locale: 'fr-TN', link: '/tn/fr/', analytics_title: 'Tunisie-english' },
      { label: 'Uganda', locale: 'en-UG', link: '/ug/en/', analytics_title: 'Uganda-english' },
      { label: 'الإمارات العربية المتحدة', locale: 'ar-AE', link: '/ae/ar/', analytics_title: 'United Arab Emirates-arabic' },
      { label: 'United Arab Emirates', locale: 'en-AE', link: '/ae/en/', analytics_title: 'United Arab Emirates-english' },
      {
        label: 'Other Africa and Middle East',
        locale: 'en-AMEI',
        link: '/amei/en/',
        analytics_title: 'Other Africa and Middle East-english',
      },
      {
        label: "Autres pays d'Afrique et du Moyen-Orient (Français)",
        locale: 'fr-AMEI',
        link: '/amei/fr/',
        analytics_title: 'Other Africa and Middle East-french',
      },
    ],
  },
  {
    name: 'apac',
    label: 'Asia Pacific',
    countries: [
      { label: 'Australia', locale: 'en-AU', link: '/au/en/', analytics_title: 'australia' },
      { label: 'Bangladesh', locale: 'en-BD', link: '/bd/en/', analytics_title: 'bangladesh' },
      { label: 'Brunei', locale: 'en-BN', link: '/bn/en/', analytics_title: 'Brunei' },
      { label: '中国大陆', locale: 'zh-CN', link: '/cn/zh/', analytics_title: 'china' },
      { label: 'Cook Islands', locale: 'en-CK', link: '/ck/en/', analytics_title: 'Cook Islands' },
      { label: 'Fiji', locale: 'en-FJ', link: '/fj/en/', analytics_title: 'Fiji' },
      { label: 'Guam', locale: 'en-GU', link: '/gu/en/', analytics_title: 'Guam' },
      { label: 'Hong Kong (English)', locale: 'en-HK', link: '/hk/en/', analytics_title: 'hong kong - english' },
      { label: '香港', locale: 'zh-HK', link: '/hk/zh/', analytics_title: 'hong kong - chinese' },
      { label: 'Indonesia', locale: 'id-ID', link: '/id/id/', analytics_title: 'indonesia - chinese' },
      { label: '日本', locale: 'ja-JP', link: '/jp/ja/', analytics_title: 'japan' },
      { label: '대한민국', locale: 'ko-KR', link: '/kr/ko/', analytics_title: 'korea' },
      { label: 'Malaysia', locale: 'en-MY', link: '/my/en/', analytics_title: 'Malaysia' },
      { label: 'Maldives', locale: 'en-MV', link: '/mv/en/', analytics_title: 'Maldives' },
      { label: '澳門', locale: 'zh-MO', link: '/mo/zh/', analytics_title: '澳門' },
      { label: 'Nepal', locale: 'en-NP', link: '/np/en/', analytics_title: 'Nepal' },
      { label: 'New Zealand', locale: 'en-NZ', link: '/nz/en/', analytics_title: 'New Zealand - english' },
      { label: 'Nouvelle-Calédonie', locale: 'en-NC', link: '/nc/en/', analytics_title: 'Nouvelle-Calédonie - english' },
      { label: 'Pakistan', locale: 'en-PK', link: '/pk/en/', analytics_title: 'Pakistan - english' },
      { label: 'Papua New Guinea', locale: 'en-PG', link: '/pg/en/', analytics_title: 'Papua New Guinea - english' },
      { label: 'Philippines', locale: 'en-PH', link: '/ph/en/', analytics_title: 'Philippines - english' },
      { label: 'Polynésie française', locale: 'en-PF', link: '/pf/en/', analytics_title: 'Polynésie française - english' },
      { label: 'Singapore', locale: 'en-SG', link: '/sg/en/', analytics_title: 'Singapore - english' },
      { label: 'Sri Lanka', locale: 'en-LK', link: '/lk/en/', analytics_title: 'Sri Lanka - english' },
      { label: 'Tonga', locale: 'en-TO', link: '/to/en/', analytics_title: 'Tonga - english' },
      { label: '台灣', locale: 'zh-TW', link: '/tw/zh/', analytics_title: 'taiwan - chinese' },
      { label: 'เมืองไทย', locale: 'th-TH', link: '/th/th/', analytics_title: 'thailand' },
      { label: 'Vanuatu', locale: 'en-VU', link: '/vu/en/', analytics_title: 'Vanuatu' },
      { label: 'Việt Nam', locale: 'vi-VN', link: '/vn/vi/', analytics_title: 'Vietnam' },
      { label: 'Other Asia', locale: 'en-ASIA', link: '/asia/en/', analytics_title: 'Other Asia' },
    ],
  },
  {
    name: 'europe',
    label: 'Europe',
    countries: [
      { label: 'Armenia', locale: 'en-AM', link: '/am/en/', analytics_title: 'armenia' },
      { label: 'Azerbaijan', locale: 'en-AZ', link: '/az/en/', analytics_title: 'azerbaijan' },
      { label: 'Belarus', locale: 'en-BY', link: '/by/en/', analytics_title: 'belarus' },
      { label: 'België', locale: 'nl-BE', link: '/be/nl/', analytics_title: 'belgië' },
      { label: 'Belgique', locale: 'fr-BE', link: '/be/fr/', analytics_title: 'belgique' },
      { label: 'България', locale: 'en-BG', link: '/bg/en/', analytics_title: 'България' },
      { label: 'Česko', locale: 'cs-CZ', link: '/cz/cs/', analytics_title: 'Česko' },
      { label: 'Cyprus', locale: 'en-CY', link: '/cy/en/', analytics_title: 'Cyprus' },
      { label: 'Danmark', locale: 'da-DK', link: '/dk/da/', analytics_title: 'Danmark' },
      { label: 'Deutschland', locale: 'de-DE', link: '/de/de/', analytics_title: 'Deutschland' },
      { label: 'Eesti', locale: 'et-EE', link: '/ee/et/', analytics_title: 'Eesti' },
      { label: 'España', locale: 'es-ES', link: '/es/es/', analytics_title: 'España' },
      { label: 'Ελλάδα', locale: 'el-GR', link: '/gr/el/', analytics_title: 'Ελλάδα' },
      { label: 'Faroe Islands', locale: 'en-FO', link: '/fo/en/', analytics_title: 'Faroe Islands' },
      { label: 'France', locale: 'fr-FR', link: '/fr/fr/', analytics_title: 'France' },
      { label: 'Georgia', locale: 'en-GE', link: '/ge/en/', analytics_title: 'Georgia' },
      { label: 'Gibraltar', locale: 'es-GI', link: '/gi/es/', analytics_title: 'Gibraltar' },
      { label: 'Guernsey', locale: 'en-GG', link: '/gg/en/', analytics_title: 'Guernsey' },
      { label: 'Hrvatska', locale: 'hr-HR', link: '/hr/hr/', analytics_title: 'Hrvatska' },
      { label: 'Iceland', locale: 'en-IS', link: '/is/en/', analytics_title: 'Iceland' },
      { label: 'Ireland', locale: 'en-IE', link: '/ie/en/', analytics_title: 'Ireland' },
      { label: 'Italia', locale: 'it-IT', link: '/it/it/', analytics_title: 'Italia' },
      { label: 'Jersey', locale: 'en-JE', link: '/je/en/', analytics_title: 'Jersey' },
      { label: 'Kazakhstan', locale: 'en-KZ', link: '/kz/en/', analytics_title: 'Kazakhstan' },
      { label: 'Kyrgyzstan', locale: 'en-KG', link: '/kg/en/', analytics_title: 'Kyrgyzstan' },
      { label: 'Latvija', locale: 'lv-LV', link: '/lv/lv/', analytics_title: 'Latvija' },
      { label: 'Liechtenstein', locale: 'de-LI', link: '/li/de/', analytics_title: 'Liechtenstein' },
      { label: 'Lietuva', locale: 'lt-LT', link: '/lt/lt/', analytics_title: 'Lietuva' },
      { label: 'Luxembourg', locale: 'fr-LU', link: '/lu/fr/', analytics_title: 'Luxembourg' },
      { label: 'Macedonia', locale: 'en-MK', link: '/mk/en/', analytics_title: 'Macedonia' },
      { label: 'Magyarország', locale: 'hu-HU', link: '/hu/hu/', analytics_title: 'Magyarország' },
      { label: 'Malta', locale: 'en-MT', link: '/mt/en/', analytics_title: 'Malta' },
      { label: 'Moldova', locale: 'en-MD', link: '/md/en/', analytics_title: 'Moldova' },
      { label: 'Monaco', locale: 'en-MC', link: '/mc/en/', analytics_title: 'Monaco' },
      { label: 'Montenegro', locale: 'en-ME', link: '/me/en/', analytics_title: 'Montenegro' },
      { label: 'Nederland', locale: 'nl-NL', link: '/nl/nl/', analytics_title: 'Nederland' },
      { label: 'Norge', locale: 'no-NO', link: '/no/no/', analytics_title: 'Norge' },
      { label: 'Österreich', locale: 'de-AT', link: '/at/de/', analytics_title: 'Österreich' },
      { label: 'Polska', locale: 'pl-PL', link: '/pl/pl/', analytics_title: 'Polska' },
      { label: 'Portugal', locale: 'pt-PT', link: '/pt/pt/', analytics_title: 'Portugal' },
      { label: 'România', locale: 'ro-RO', link: '/ro/ro/', analytics_title: 'România' },
      { label: 'Россия', locale: 'ru-RU', link: '/ru/ru/', analytics_title: 'Россия' },
      { label: 'San Marino', locale: 'en-SM', link: '/sm/en/', analytics_title: 'San Marino' },
      { label: 'Schweiz', locale: 'de-CH', link: '/ch/de/', analytics_title: 'Schweiz' },
      { label: 'Slovenia', locale: 'en-SI', link: '/si/en/', analytics_title: 'Slovenia' },
      { label: 'Slovensko', locale: 'sk-SK', link: '/sk/sk/', analytics_title: 'Slovensko' },
      { label: 'Suisse', locale: 'fr-CH', link: '/ch/fr/', analytics_title: 'Suisse' },
      { label: 'Suomi', locale: 'fi-FI', link: '/fi/fi/', analytics_title: 'Suomi' },
      { label: 'Sverige', locale: 'sv-SE', link: '/se/sv/', analytics_title: 'Sverige' },
      { label: 'Tajikistan', locale: 'en-TJ', link: '/tj/en/', analytics_title: 'Tajikistan' },
      { label: 'Türkiye', locale: 'tr-TR', link: '/tr/tr/', analytics_title: 'Türkiye' },
      { label: 'Turkmenistan', locale: 'en-TM', link: '/tm/en/', analytics_title: 'Turkmenistan' },
      { label: 'Україна', locale: 'uk-UA', link: '/ua/uk/', analytics_title: 'Україна' },
      { label: 'UK', locale: 'en-GB', link: '/gb/en/', analytics_title: 'UK' },
      { label: 'Uzbekistan', locale: 'en-UZ', link: '/uz/en/', analytics_title: 'Uzbekistan' },
      { label: 'Other Europe', locale: 'en-EUROPE', link: '/europe/en/', analytics_title: 'Other Europe' },
    ],
  },
  {
    name: 'latin-america',
    label: 'Latin America and the Caribbean',
    countries: [
      { label: 'Anguilla', locale: 'en-AI', link: '/ai/en/', analytics_title: 'anguilla' },
      { label: 'Antigua & Barbuda', locale: 'en-AG', link: '/ag/en/', analytics_title: 'antigua & barbuda' },
      { label: 'Argentina', locale: 'es-AR', link: '/ar/es/', analytics_title: 'Argentina' },
      { label: 'Aruba', locale: 'en-AW', link: '/aw/en/', analytics_title: 'Aruba' },
      { label: 'Barbados', locale: 'en-BB', link: '/bb/en/', analytics_title: 'Barbados' },
      { label: 'Belize', locale: 'en-BZ', link: '/bz/en/', analytics_title: 'Belize' },
      { label: 'Bermuda', locale: 'en-BM', link: '/bm/en/', analytics_title: 'Bermuda' },
      { label: 'Bolivia', locale: 'es-BO', link: '/bo/es/', analytics_title: 'Bolivia' },
      { label: 'Brasil', locale: 'pt-BR', link: '/br/pt/', analytics_title: 'Brasil' },
      { label: 'British Virgin Islands', locale: 'en-VG', link: '/vg/en/', analytics_title: 'British Virgin Islands' },
      { label: 'Cayman Islands', locale: 'en-KY', link: '/ky/en/', analytics_title: 'Cayman Islands' },
      { label: 'Chile', locale: 'es-CL', link: '/cl/es/', analytics_title: 'Chile' },
      { label: 'Colombia', locale: 'es-CO', link: '/co/es/', analytics_title: 'Colombia' },
      { label: 'Costa Rica', locale: 'es-CR', link: '/cr/es/', analytics_title: 'Costa Rica' },
      { label: 'Curaçao', locale: 'es-CW', link: '/cw/es/', analytics_title: 'Curaçao' },
      { label: 'Dominica', locale: 'en-DM', link: '/dm/en/', analytics_title: 'Dominica' },
      { label: 'Ecuador', locale: 'es-EC', link: '/ec/es/', analytics_title: 'Ecuador' },
      { label: 'El Salvador', locale: 'es-SV', link: '/sv/es/', analytics_title: 'El Salvador' },
      { label: 'Grenada', locale: 'en-GD', link: '/gd/en/', analytics_title: 'Grenada' },
      { label: 'Guatemala', locale: 'es-GT', link: '/gt/es/', analytics_title: 'Guatemala' },
      { label: 'Guadeloupe', locale: 'fr-GP', link: '/gp/fr/', analytics_title: 'Guadeloupe' },
      { label: 'Guyana', locale: 'en-GY', link: '/gy/en/', analytics_title: 'Guyana' },
      { label: 'Guyane', locale: 'fr-GF', link: '/gf/fr/', analytics_title: 'Guyane' },
      { label: 'Honduras', locale: 'es-HN', link: '/hn/es/', analytics_title: 'Honduras' },
      { label: 'Jamaica', locale: 'en-JM', link: '/jm/en/', analytics_title: 'Jamaica' },
      { label: 'Martinique', locale: 'fr-MQ', link: '/mq/fr/', analytics_title: 'Martinique' },
      { label: 'México', locale: 'es-MX', link: '/mx/es/', analytics_title: 'México' },
      { label: 'Montserrat', locale: 'en-MS', link: '/ms/en/', analytics_title: 'Montserrat' },
      { label: 'Nicaragua', locale: 'es-NI', link: '/ni/es/', analytics_title: 'Nicaragua' },
      { label: 'Panamá', locale: 'es-PA', link: '/pa/es/', analytics_title: 'Panamá' },
      { label: 'Paraguay', locale: 'es-PY', link: '/py/es/', analytics_title: 'Paraguay' },
      { label: 'Perú', locale: 'es-PE', link: '/pe/es/', analytics_title: 'Perú' },
      { label: 'República Dominicana', locale: 'es-DO', link: '/do/es/', analytics_title: 'República Dominicana' },
      { label: 'Sint Maarten', locale: 'en-SX', link: '/sx/en/', analytics_title: 'Sint Maarten' },
      { label: 'St. Kitts & Nevis', locale: 'en-KN', link: '/kn/en/', analytics_title: 'St. Kitts & Nevis' },
      { label: 'St. Lucia', locale: 'en-LC', link: '/lc/en/', analytics_title: 'St. Lucia' },
      { label: 'St. Vincent & The Grenadines', locale: 'en-VC', link: '/vc/en/', analytics_title: 'St. Vincent & The Grenadines' },
      { label: 'Suriname', locale: 'en-SR', link: '/sr/en/', analytics_title: 'Suriname' },
      { label: 'The Bahamas', locale: 'en-BS', link: '/bs/en/', analytics_title: 'The Bahamas' },
      { label: 'Trinidad & Tobago', locale: 'en-TT', link: '/tt/en/', analytics_title: 'Trinidad & Tobago' },
      { label: 'Turks & Caicos', locale: 'en-TC', link: '/tc/en/', analytics_title: 'Turks & Caicos' },
      { label: 'Uruguay', locale: 'es-UY', link: '/uy/es/', analytics_title: 'Uruguay' },
      { label: 'U.S. Virgin Islands', locale: 'en-VI', link: '/vi/en/', analytics_title: 'U.S. Virgin Islands' },
      { label: 'Venezuela', locale: 'es-VE', link: '/ve/es/', analytics_title: 'Venezuela' },
      {
        label: 'América Latina y el Caribe (español)',
        locale: 'es-LAC',
        link: '/lac/es/',
        analytics_title: 'América Latina y el Caribe (español)',
      },
      {
        label: 'Latin America and the Caribbean (English)',
        locale: 'en-LAC',
        link: '/lac/en/',
        analytics_title: 'Latin America and the Caribbean (English)',
      },
      {
        label: 'Amérique latine et les Caraïbes (Français)',
        locale: 'fr-LAC',
        link: '/lac/fr/',
        analytics_title: 'Amérique latine et les Caraïbes (Français)',
      },
    ],
  },
  {
    name: 'north-america',
    label: 'The United States, Canada, and Puerto Rico',
    countries: [
      { label: 'Canada (English)', locale: 'en-CA', link: '/ca/en/', analytics_title: 'canada-english' },
      { label: 'Canada (Français)', locale: 'fr-CA', link: '/ca/fr/', analytics_title: 'canada-french' },
      { label: 'Puerto Rico (English)', locale: 'en-PR', link: '/pr/en/', analytics_title: 'puerto-rico-english' },
      { label: 'Puerto Rico (español)', locale: 'es-PR', link: '/pr/es/', analytics_title: 'puerto-rico-spanish' },
      { label: 'United States', locale: 'en-US', link: '/us/en/', analytics_title: 'united states' },
    ],
  },
];
