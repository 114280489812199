import { EVENT_MAP, OTHER_COUNTRIES } from '../constants';
import { ChannelMap, IFormItem, IForms } from '../models';
import { Product, Country } from '../models/api-interface';
import sortBy from 'lodash-es/sortBy';
import Analytics from '@sse/neighbor';

export const getFormSchema = (formInfo: IForms, country: string, lang: string): IFormItem => {
  let formData: IFormItem = {};
  const countryMap = OTHER_COUNTRIES.includes(country) ? 'other' : country + '_' + lang;
  const locale = formInfo[countryMap];
  if (locale) {
    formData = locale;
  } else {
    formData = formInfo['default'];
  }
  return formData;
};

export const populateData = (formSchema: IFormItem, data: ChannelMap, channel: string): IFormItem => {
  const options = data[channel] && data[channel].options;
  const mappedSchema = { ...formSchema };
  mappedSchema[channel].forEach((schema: any) => {
    if (options && Object.keys(options).length > 0) {
      if (schema.name === 'country') {
        const countries = options['country']
          ? options['country'].map((opt: Country) => ({
              id: opt.countryCode,
              name: opt.countryName,
              code: opt.countryCode,
              value: opt.countryName,
              products: opt.products,
              carriers: opt.carriers,
            }))
          : [];
        schema.options = countries;
      }
      if (schema.name === 'products') {
        const products = sortBy(options['products'], ['displayOrder']);
        const opts = products.map((opt: Product) => ({
          id: opt._id,
          name: opt.displayName,
          code: opt.code,
          value: opt.value,
        }));
        channel === 'sales'
          ? (schema.options = [{ id: 0, name: 'All Products', code: 'allproducts', value: 'All Products' }, ...opts])
          : (schema.options = [...opts]);
      }
      if (schema.name === 'carrier') {
        const carriers = options['carriers']
          ? options['carriers'].map((opt: any) => {
              return {
                id: opt.name,
                name: opt.name,
                value: opt.name,
              };
            })
          : [];
        schema.options = carriers.length > 0 ? [{ id: 'No-Contract', name: 'No-Contract', value: 'No-Contract' }, ...carriers] : [];
      }
    }
  });
  return mappedSchema;
};

const removeUndefined = (analytics: any) => {
  Object.keys(analytics).forEach((key) => (analytics[key] === undefined || analytics[key] === null) && delete analytics[key]);
  return analytics;
};

export const trackEvent = (name: EVENT_MAP, component?: any, product?: any, search?: any, page?: any): void => {
  if (page !== undefined && page !== null) {
    _applemd.page.results = {
      current_pagination: `${page}`,
    };
  } else {
    if (_applemd.page.results) {
      delete _applemd.page['results'];
    }
  }
  const analyticsObj = removeUndefined({ ..._applemd, component, search, product });
  _applemd = { ...analyticsObj };
  if (name === EVENT_MAP.PAGE_LOAD) {
    Analytics.init(neighborMeta);
    Analytics.load(_applemd);
    _satellite.track('loc.page');
  } else if (name === EVENT_MAP.CLICK) {
    Analytics.action(_applemd);
    _satellite.track('loc.link');
  } else if (name === EVENT_MAP.SEARCH) {
    Analytics.action(_applemd);
    _satellite.track('loc.search');
  }
};
