import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ReactComponent as FilterFilled } from '../../assets/filter_filled.svg';
import { ReactComponent as FilterUnFilled } from '../../assets/filter_unfilled.svg';
import { Alert, Toggle } from '../../components/ac-forms';
import SimpleDropdown from '../../components/ac-forms/simple-dropdown/simple-dropdown';
import { Values } from '../../components/ac-forms/use-form/models';
import ActiveFilters from '../../components/active-filters';
import BadgeInfo from '../../components/badge-info';
import Banner from '../../components/banner';
import ErrorBoundary from '../../components/error-boundary';
import Form from '../../components/form';
import Icon from '../../components/icon';
import Loader from '../../components/loader';
import LocalNav from '../../components/localnav';
import Map from '../../components/maps';
import AddressCard from '../../components/maps/components/address-card';
import Marker from '../../components/maps/components/marker-annotation';
import { createCoordinate } from '../../components/maps/map-utility';
import { Directions } from '../../components/maps/services/directions';
import NoMatch from '../../components/no-match/no-match';
import NoResult from '../../components/no-results';
import Pagination from '../../components/pagination';
import StoreDetail from '../../components/store-detail/StoreDetail';
import StoreInfo from '../../components/store-info/store-info';
import Stores from '../../components/stores';
import Summary from '../../components/summary';
import WidgetInfo from '../../components/widget-info';
import {
  BADGE_MAP,
  CHANNELS,
  CHINESE_LANG_COUNTRIES,
  EVENT_MAP,
  NOTICE_EXCEPTIONS,
  OTHER_COUNTRIES,
  SOLUTION_SPECIALITIES,
} from '../../constants';
import { FORMS, MOBILE_FORMS } from '../../constants/forms';
import { LOCALE_MAPPING } from '../../constants/locale-mapping';
import useTitle from '../../hooks/use-title';
import Layout from '../../layout';
import { FormElement, IFormItem } from '../../models';
import { Product } from '../../models/api-interface';
import { ModifiedStore } from '../../models/store-models';
import { getFormSchema, populateData, trackEvent } from '../../services/utility';
import { fetchMapKitToken, fetchResources, populateResources, setSearchSubmitted } from '../../store/actions';
import { Actions, SearchPayload } from '../../store/model';
import { StoreContext } from '../../store/store';
import { Sanitized } from '@idms/react-sanitized';
import './results-page.scss';

const PAGE_LIMIT = 5;

const ResultsPage = (): JSX.Element => {
  const { store, dispatch } = useContext(StoreContext);
  const { loading, fetching_results, global, channels, results, token, errors } = store;
  const [query, setQuery] = useState<SearchPayload>({} as SearchPayload);
  const [active, setActive] = useState<number>(0);
  const [focus, setFocus] = useState<number>(0);
  const [activeStore, setActiveStore] = useState<ModifiedStore>();
  const [formConfig, setFormConfig] = useState<IFormItem>({} as IFormItem);
  const [adjustMap, setAdjustMap] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [stores, setStores] = useState<ModifiedStore[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [filteredStores, setFilteredStores] = useState<ModifiedStore[]>([]);
  const [highlightStore, setHighlightStore] = useState(0);
  const [ipadSize, setIpadSize] = useState(false);

  const resultsRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const noResultRef = useRef<HTMLDivElement>(null);
  const filterToggleRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const mediumDevices = 'screen and (max-width: 1068px)';
  const { channel, lang, country } = useParams<{ [key: string]: string }>();
  const { i18n, t, ready } = useTranslation();

  useTitle(t(`${channel}.results.title`) + ' - ' + t('page.results.localNavTitle') + ' - Apple');

  const isDropOffScreen = window.location.href.indexOf('repairType=Drop') === -1;

  const selectedLocale = useMemo(() => {
    const browserLocale = (document.getElementById('locale') as HTMLInputElement).value || '';
    return browserLocale;
  }, []);
  const langs = selectedLocale.toLowerCase().split('-');

  const salesCarriers = useMemo(() => {
    const carriers = global && global.carriers && global.carriers.filter((carrier) => carrier.salesURL).map((carrier) => carrier.name);
    return carriers || [];
  }, [global?.carriers]);

  const badges = useMemo(() => {
    return results ? results.badgesArray.filter((badge) => badge.desc === 'y') : [];
  }, [results?.badgesArray]);

  const directionSearch = useMemo(() => {
    return new Directions({ language: lang });
  }, []);

  useEffect(() => {
    const path = LOCALE_MAPPING[selectedLocale];
    if (selectedLocale && !CHANNELS.includes(channel.toLowerCase())) {
      history.push(path);
    }
    if (selectedLocale && CHANNELS.includes(channel.toLowerCase())) {
      if (path === '/' || !history.location.pathname.includes(path)) {
        history.push(`${path}${channel}`);
      } else {
        history.push(`${path}${channel}${history.location.search}`);
      }
    }
    i18n.changeLanguage(selectedLocale);
    !token && fetchMapKitToken(dispatch);
    if (globalObject && globalObject.response && globalObject.response.length > 0) {
      populateResources(dispatch, globalObject.response, { lang: langs[0], countryCode: langs[1] });
    } else {
      !global && fetchResources(dispatch, { lang: langs[0], countryCode: langs[1] });
    }
    if (__satelliteLoaded) {
      _applemd.page.clean_url = window.location.href;
      _applemd.page.locale = selectedLocale.toLowerCase();
      _applemd.page.support_site = `locate.${langs[1].toLowerCase()}`;
      _applemd.page.content_subtype = `find locations::${channel}`;
      trackEvent(EVENT_MAP.PAGE_LOAD);
    }
  }, []);

  useEffect(() => {
    const buildForm =
      Object.keys(channels).length > 0 &&
      populateData(getFormSchema(ipadSize ? MOBILE_FORMS : FORMS, langs[1], langs[0]), channels, channel);
    if (buildForm) {
      setFormConfig(buildForm);
    }
    if (history.location.search && Object.keys(channels).length > 0) {
      const queryParams = new URLSearchParams(history.location.search);
      const newQuery: { [key: string]: any } = {};
      queryParams.forEach((val, key) => (newQuery[key] = val));
      const prod = getProductNameById(newQuery.pt);
      if (newQuery.lat && newQuery.lon && newQuery.address) {
        const buildQuery: SearchPayload = {
          latitude: newQuery.lat,
          longitude: newQuery.lon,
          channel,
          lang: langs[0],
          countryCode: newQuery.countryCode || langs[1],
          productId: newQuery.pt,
          product: prod,
          carriers: newQuery.carrier,
          formattedAddress: newQuery.address,
          maxRadius: channels[channel].searchRadius,
          maxResults: channels[channel].maxResults,
        };
        setQuery(buildQuery);
        if (!isDropOffScreen) {
          setSearchSubmitted(dispatch, { ...buildQuery, repairType: 'Drop off' });
        } else {
          setSearchSubmitted(dispatch, buildQuery);
        }
      } else {
        history.push(history.location.pathname);
      }
    }
  }, [channels]);

  useEffect(() => {
    const smallDevice = window.matchMedia(mediumDevices);
    if (!ipadSize) {
      setIpadSize(smallDevice.matches);
    }
    smallDevice.addEventListener('change', () => setIpadSize(smallDevice.matches));
    return () => smallDevice.removeEventListener('change', () => () => setIpadSize(smallDevice.matches));
  }, []);

  useEffect(() => {
    if (token) {
      mapkit.init({
        authorizationCallback: (done: (token: string) => void) => done(token),
        language: langs[0],
      });
    }
  }, [token]);

  useEffect(() => {
    if (results && results.stores.length > 0) {
      const initialStores = results.stores.slice(0, PAGE_LIMIT);
      setStores(results.stores);
      setFilteredStores(initialStores);
      setSelectedFilter([]);
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      setTimeout(() => {
        setAdjustMap(1);
      }, 1000);
      trackEvent(
        EVENT_MAP.SEARCH,
        {
          name: 'find locations',
          type: 'impression',
          link_text: 'results view',
        },
        {
          list: [query.product],
        },
        {
          term: query.formattedAddress,
          results: `${results.stores.length}`,
          resultType: 'user',
        },
        1,
      );
    } else {
      if (results && results.stores.length === 0) {
        trackEvent(
          EVENT_MAP.SEARCH,
          {
            name: 'find locations',
            type: 'impression',
            link_text: 'results view',
          },
          {
            list: [query.product],
          },
          {
            term: query.formattedAddress,
            results: '0',
            resultType: 'user',
          },
          0,
        );
      }
      loaderRef.current && loaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      noResultRef.current && noResultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }, [results]);

  useEffect(() => {
    const backToStore = document.querySelector('.show-back-button a') as HTMLButtonElement;
    if (backToStore && activeStore) {
      backToStore.focus();
    }
  }, [activeStore]);

  const handleSelection = (index: number) => {
    const findStore = filteredStores.find((store) => store.orderNumber === index);
    setActive(index);
    setActiveStore(findStore);
    if (findStore) {
      trackEvent(
        EVENT_MAP.SEARCH,
        {
          link_owner: 'ac_link',
          name: 'store list view',
          type: 'simple link',
          link_text: findStore.title,
        },
        { list: [query.product] },
        {
          term: query.formattedAddress,
          results: `${results?.stores.length || 0}`,
          resultType: 'user',
          store_ID: findStore.storeId,
          resultViewType: 'find location results',
          result_index: `${index}`,
        },
      );
    }
  };

  const handleFilters = () => {
    setOpenFilter(!openFilter);
    trackEvent(
      EVENT_MAP.CLICK,
      {
        link_owner: 'ac_link',
        type: 'simple link',
        link_text: 'more filters',
      },
      {
        list: [query.product],
      },
    );
  };

  const onPageChanged = useCallback(
    (page: number) => {
      const offset = (page - 1) * PAGE_LIMIT;
      const currentStores = stores ? stores.slice(offset, offset + PAGE_LIMIT) : [];
      setFilteredStores(currentStores);
      setCurrentPage(page);
      setAdjustMap(page);
      trackEvent(
        EVENT_MAP.CLICK,
        {
          link_owner: 'ac_link',
          name: 'pagination',
          type: 'simple link',
          link_text: page,
        },
        {
          list: [query.product],
        },
      );
    },
    [stores],
  );

  const getProductIdByName = (productName: string, countryCode: string) => {
    let products = channels[channel].options.products;
    const countries = channels[channel].options.country;
    if (countries && countries.length > 0) {
      products = channels[channel].options.country?.find((country) => country.countryCode === countryCode)?.products;
    }
    const selectedProd = products ? products.filter((product) => product.displayName === productName) : [];
    return selectedProd.length > 0 ? selectedProd[0]._id.toString() : 'all';
  };

  const getProductNameById = (productId: string) => {
    const products = channels[channel].options.products;
    const selectedProd = products ? products.filter((product) => product._id === parseInt(productId)) : [];
    return selectedProd.length > 0 ? selectedProd[0].displayName.toString() : 'All Products';
  };

  const getProductCodeById = (productId: string) => {
    const products = channels[channel].options.products;
    const selectedProd = products ? products.filter((product) => product._id === parseInt(productId)) : [];
    return selectedProd.length > 0 ? selectedProd[0].code : 'all';
  };

  const updateQueryParams = (
    carrier: string,
    address: string,
    prodId: string,
    lat: string,
    lng: string,
    countryCode = '',
    repairType = '',
  ) => {
    const pathname = history.location.pathname;
    const searchParams = new URLSearchParams();
    prodId && searchParams.set('pt', prodId);
    countryCode && searchParams.set('countryCode', countryCode);
    lat && searchParams.set('lat', lat);
    lng && searchParams.set('lon', lng);
    address && searchParams.set('address', address);
    carrier && searchParams.set('carrier', carrier);
    repairType && searchParams.set('repairType', repairType);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const getPath = (path: string, region: string, country: string) => (path ? path.replace(region, country) : '');

  const handleSubmit = async (values: Values) => {
    const prodId = getProductIdByName(values.products, values.countryCode);
    const buildQuery: SearchPayload = {
      latitude: values.latitude,
      longitude: values.longitude,
      channel,
      lang: langs[0],
      countryCode: values.countryCode,
      productId: prodId,
      product: values.products,
      maxRadius: channels[channel].searchRadius,
      maxResults: channels[channel].maxResults,
      carriers: values.carrier,
      formattedAddress: values.formattedAddress,
    };
    setQuery(buildQuery);
    if (OTHER_COUNTRIES.includes(langs[1])) {
      const newPath = getPath(history.location.pathname, langs[1], values.countryCode);
      history.replace({ pathname: newPath });
      updateQueryParams(values.carrier, values.formattedAddress, prodId, values.latitude, values.longitude, values.countryCode);
    } else {
      updateQueryParams(values.carrier, values.formattedAddress, prodId, values.latitude, values.longitude);
    }
    setSearchSubmitted(dispatch, buildQuery);
    setCurrentPage(1);
    trackEvent(
      EVENT_MAP.CLICK,
      {
        link_owner: 'ac_link',
        name: values.country ? 'loc multi-country search' : 'loc simple search',
        type: 'simple link',
        link_text: 'search',
        dest_url: window.location.href,
      },
      {
        list: [values.products],
      },
      {
        location: 'loc search component',
        link_type: 'user',
        link_text: values.search,
      },
    );
  };

  const handleDropOff = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    updateQueryParams(
      query.carriers || '',
      query.formattedAddress,
      query.productId,
      query.latitude.toString(),
      query.longitude.toString(),
      '',
      'Drop off',
    );
    setSearchSubmitted(dispatch, { ...query, repairType: 'Drop off' });
    setCurrentPage(1);
  };

  const handleOnSite = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    updateQueryParams(query.carriers || '', query.formattedAddress, query.productId, query.latitude.toString(), query.longitude.toString());
    setSearchSubmitted(dispatch, query);
    setCurrentPage(1);
  };

  const handleHover = (orderNum: number, hover: boolean) => (hover ? setHighlightStore(orderNum) : setHighlightStore(0));

  const findAllElements = (elements: any[], find: any[]): boolean => find.every((item) => elements.includes(item));

  const findAnyElements = (elements: any[], find: any[]): boolean => find.some((item) => elements.includes(item));

  const filterStoresByProduct = (selection: string[]) => {
    const isServiceOrSales = selection.find((a) => a === 'service' || a === 'sales');
    const storeType = selection.filter((a) => BADGE_MAP[a]);
    const solutionType = selection.filter((item) => SOLUTION_SPECIALITIES.includes(item));
    const selected = selection.filter(
      (select) => select !== 'service' && select !== 'sales' && !BADGE_MAP[select] && !SOLUTION_SPECIALITIES.includes(select),
    );
    let filtered = results?.stores.filter((store) => {
      const products = channel === 'sales' ? store.salesProducts : store.serviceProducts ? store.serviceProducts : [];
      const productKeys = products.map((prod) => prod.code);
      const allKeys = [...productKeys];
      if (isServiceOrSales && selected.length > 0) {
        const serviceProducts = (store.serviceProducts || []).map((prod) => prod.code);
        const salesProducts = store.salesProducts.map((prod) => prod.code);
        if (query.productId !== 'all') {
          const selectedProduct = getProductCodeById(query.productId);
          selected.push(selectedProduct);
        }
        return findAllElements(serviceProducts, selected) && findAllElements(salesProducts, selected);
      } else if (isServiceOrSales && selected.length === 0) {
        const alternativeStores = isServiceOrSales === 'sales' ? store.salesProducts : store?.serviceProducts || [];
        const serviceKeys = alternativeStores.map((prod) => prod.code);
        const salesKeys = store.salesProducts.map((prod) => prod.code);
        if (serviceKeys.length > 0) {
          if (query.productId !== 'all') {
            const selectedProduct = getProductCodeById(query.productId);
            return findAllElements(serviceKeys, [selectedProduct]) && findAllElements(salesKeys, [selectedProduct]);
          } else {
            return findAnyElements(serviceKeys, salesKeys);
          }
        } else {
          return false;
        }
      } else {
        return findAllElements(allKeys, selected);
      }
    });
    if (solutionType.length > 0) {
      filtered = filtered?.filter((filter) =>
        findAnyElements(
          (filter.solutionsSpecialties || []).map((item) => item.code),
          solutionType,
        ),
      );
    }
    if (storeType.length > 0) {
      filtered = filtered?.filter((filter) => findAnyElements(filter.storeBadges || [], storeType));
    }
    return filtered;
  };

  const setFilterState = (value: string): string[] => {
    const existValue = selectedFilter.find((val) => val === value);
    let selected = [];
    if (existValue) {
      selected = selectedFilter.filter((val) => val !== value);
    } else {
      selected = [...selectedFilter, value];
    }
    setSelectedFilter(selected);
    return selected;
  };

  const onFilterReset = () => {
    const ariaStatusIndicator = document.querySelector('div.status-indicator') as HTMLDivElement;
    ariaStatusIndicator.innerText = t('page.results.filters.statusText');
    setSelectedFilter([]);
    if (results && results.stores) {
      const initialStores = results.stores.slice(0, PAGE_LIMIT);
      setStores(results.stores);
      setFilteredStores(initialStores);
    }
    setCurrentPage(1);
    setFocus(0);
    setActive(0);
    if (filterToggleRef.current) {
      filterToggleRef.current.focus();
      setTimeout(() => {
        ariaStatusIndicator.innerText = '';
      }, 4000);
    }
  };

  const onFilterChange = (selection: string) => {
    const filterState = setFilterState(selection);
    trackEvent(
      EVENT_MAP.SEARCH,
      {
        type: 'filter results',
        link_text: `${filterState.join(',')}`,
      },
      {
        list: [query.product],
      },
      {
        term: query.formattedAddress,
        results: `${results?.stores.length || 0}`,
        resultType: 'filtered',
      },
      1,
    );
    if (filterState && filterState.length === 0 && results && results.stores) {
      const initialStores = results.stores.slice(0, PAGE_LIMIT);
      setStores(results.stores);
      setFilteredStores(initialStores);
    } else {
      const filteredStores = filterStoresByProduct(filterState) || [];
      const currentStores = filteredStores ? filteredStores.slice(0, PAGE_LIMIT) : [];
      setStores(filteredStores);
      setFilteredStores(currentStores);
    }
    setCurrentPage(1);
  };

  const filterReset = () => {
    if (results && results.stores && results.stores.length > 0) {
      const initialStores = results.stores.slice(0, PAGE_LIMIT);
      setStores(results.stores);
      setFilteredStores(initialStores);
      setSelectedFilter([]);
      setActive(0);
      setFocus(0);
    }
    if (filterToggleRef.current) {
      filterToggleRef.current.focus();
    }
  };

  const handleFormChange = (field: string, selectedValue: any, element: FormElement) => {
    if (field === 'country') {
      const country = element.options?.find((elem) => elem.name === selectedValue);
      if (country) {
        const prods = country.products.map((prod: Product) => ({
          id: prod._id,
          name: prod.displayName,
          code: prod.code,
          value: prod.value,
        }));
        const carriers =
          country.carriers &&
          country.carriers.map((carrier: any) => ({
            id: carrier.name,
            name: carrier.name,
            value: carrier.name,
          }));
        const channelConfig = formConfig[channel].map((config) => {
          const modified = { ...config };
          if (field === 'country') {
            if (config.name === 'products') {
              if (channel === 'sales') {
                modified.options = config.options ? [config.options[0], ...prods] : [...prods];
              } else {
                modified.options = [...prods];
              }
            }
            if (config.name === 'carrier') {
              if (channel === 'service') {
                modified.options = carriers;
              }
            }
          }
          return modified;
        });
        const modifiedConfig = { ...formConfig, [channel]: channelConfig };
        setFormConfig(modifiedConfig);
      }
    }
  };

  const handleReset = () => {
    history.push({ search: '' });
    dispatch({ type: Actions.RESET_RESULTS });
  };

  const onCarrierSelected = (event: any) => {
    const selectedCarrier = global?.carriers.find((carrier) => carrier.name === event.target.value);
    if (selectedCarrier) {
      trackEvent(EVENT_MAP.CLICK, {
        link_owner: 'ac_link',
        name: 'select carrier',
        type: 'message ribbon',
        link_text: selectedCarrier?.name,
        dest_url: selectedCarrier?.salesURL,
      });
      const newWindow = window.open(selectedCarrier.salesURL, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };

  const getCarrierInfo = (carrier = '') => {
    const carrierLink = global?.carriers && global.carriers.find((car) => car.name === carrier);
    return carrierLink ? { carrier: carrierLink.name, carrierLink: carrierLink.serviceURL } : { carrier, carrierLink: carrier };
  };

  const corporateResellerClick = (event: any) => {
    trackEvent(EVENT_MAP.CLICK, {
      link_owner: 'ac_link',
      name: event.target.innerText,
      type: 'simple link',
      link_text: event.target.innerText,
      dest_url: event.target.href,
    });
  };

  const handleBackToStores = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveStore(undefined);
    setFocus(activeStore?.orderNumber || 0);
  };

  const handleCloseOnEscape = (event: React.KeyboardEvent, orderNum: number) => {
    event.stopPropagation();
    event.preventDefault();
    setActive(0);
    setFocus(orderNum);
  };

  const handleFocusToList = (event: React.MouseEvent, orderNum: number) => {
    event.stopPropagation();
    event.preventDefault();
    setActive(0);
    setFocus(orderNum);
  };

  return (
    <Fragment>
      {ready && (
        <Fragment>
          <LocalNav stickNav border collapseAt="small" initialState="default" title={t('page.results.localNavTitle')} />
          <div className="status-indicator visuallyhidden" role="status" aria-live="polite"></div>
          <ErrorBoundary>
            <Layout>
              {(loading || !global) && (
                <div className="loading-container">
                  <Loader ariaLabel="Loading main content" />
                </div>
              )}
              {errors && (
                <Alert type="error" description="">
                  <span className="icon icon-exclamationcircle"></span>
                  &nbsp;&nbsp;{t('error.serverError')}
                </Alert>
              )}
              {!loading && global && (
                <Fragment>
                  {channel && formConfig && (
                    <Fragment>
                      <WidgetInfo channel={channel} locale={selectedLocale} />
                      <Form
                        type={channel}
                        defaultValues={query}
                        countryCode={langs[1]}
                        lang={langs[0]}
                        className={`${channel}-form`}
                        elements={formConfig[channel]}
                        onChange={handleFormChange}
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                      />
                    </Fragment>
                  )}
                  {channel === 'sales' && CHINESE_LANG_COUNTRIES.includes(langs[1]) && (
                    <Fragment>
                      <div className="seperator"></div>
                      {langs[0] === 'zh' ? (
                        <div className="corporate">
                          <a
                            className="button icon icon-after icon-external"
                            onClick={corporateResellerClick}
                            href={`https://www.apple.com/${langs[1]}/buy/reseller/enterprise.html`}>
                            {t('form.resellerSalesText')}
                          </a>
                        </div>
                      ) : (
                        <div className="corporate">
                          <a
                            className="button icon icon-after icon-external"
                            onClick={corporateResellerClick}
                            href={`https://www.apple.com/${langs[1]}/en/buy/reseller/enterprise.html`}>
                            {t('form.resellerSalesText')}
                          </a>
                        </div>
                      )}
                    </Fragment>
                  )}
                  {channel === 'service' && langs[1] === 'cn' && langs[0] === 'zh' && (
                    <Fragment>
                      <div className="seperator"></div>
                      <div className="corporate">
                        <a
                          className="button icon icon-after icon-external"
                          onClick={corporateResellerClick}
                          href="https://www.apple.com/cn/service/provider/enterprise.html">
                          {t('form.resellerServiceText')}
                        </a>
                      </div>
                    </Fragment>
                  )}
                  <div ref={resultsRef} className="row justify-content-center results-page">
                    <div className="column large-12">
                      {results?.stores && results.stores.length > 0 && !fetching_results && (
                        <Fragment>
                          <h2 className="visuallyhidden">Results</h2>
                          <div className="seperator"></div>
                          {channel === 'service' && !NOTICE_EXCEPTIONS.includes(country?.toUpperCase()) && (
                            <Sanitized tagName="p" className="notice" html={t('page.results.noticeHeading')} />
                          )}
                          {!(activeStore && ipadSize) ? (
                            <>
                              <div className="store-results-banner">
                                <Summary
                                  displayCount={stores.length}
                                  channel={channel}
                                  product={query.product}
                                  address={query.formattedAddress}
                                  dropoff={results.dropOff && !isDropOffScreen}
                                />
                                <div className="filter-actions">
                                  {selectedFilter.length > 0 && (
                                    <button onClick={() => onFilterReset()} className="reset-filters">
                                      {t('page.results.filters.resetFilters')}
                                    </button>
                                  )}
                                  <Toggle
                                    id="more-filters"
                                    ref={filterToggleRef}
                                    className="search-more"
                                    onChange={handleFilters}
                                    aria-controls="active-filters"
                                    aria-expanded={openFilter ? true : false}>
                                    {openFilter ? <FilterFilled className="svg-icon" /> : <FilterUnFilled className="svg-icon" />}
                                    {t('page.results.filters.filterText')}
                                    {selectedFilter.length > 0 && `(${selectedFilter.length})`}
                                  </Toggle>
                                </div>
                              </div>
                              {openFilter && results?.activeFilters && (
                                <ActiveFilters
                                  defaultChannel={channel}
                                  selectedProduct={query.productId}
                                  selectionChanged={onFilterChange}
                                  selectedFilters={selectedFilter}
                                  filters={results.activeFilters}
                                  filterClosed={() => setOpenFilter(false)}
                                />
                              )}
                              {global.carriers &&
                                global.carriers.length > 0 &&
                                query.product &&
                                query.carriers?.toLowerCase() !== 'no-contract' &&
                                channel === 'service' &&
                                (query.product.toLowerCase() === 'iphone' || query.product.toLowerCase() === 'ipad') && (
                                  <Banner className="carrier-info">
                                    <Icon name="infocircle" />
                                    <Sanitized tagName="p" html={t('page.results.carrierLinkText', getCarrierInfo(query.carriers))} />
                                  </Banner>
                                )}
                              {salesCarriers.length > 0 &&
                                query.product &&
                                channel === 'sales' &&
                                (query.product.toLowerCase() === 'iphone' || query.product.toLowerCase() === 'ipad') && (
                                  <Banner className="carrier-info">
                                    <p>{t('page.results.carrierDropdownText')}</p>
                                    <SimpleDropdown
                                      label={t('form.carrier.label')}
                                      id="carriers-selection"
                                      className="carrier-selection"
                                      options={[t('form.carrier.label'), ...salesCarriers]}
                                      onChange={onCarrierSelected}
                                    />
                                  </Banner>
                                )}
                            </>
                          ) : (
                            <div className="show-back-button">
                              <Icon as="link" label="Back to stores" name={'chevronleft'} before onClick={handleBackToStores} />
                            </div>
                          )}
                          {channel === 'service' && results.dropOff && (
                            <Banner className="dropoff-info">
                              <Icon name="infocircle" />
                              {isDropOffScreen ? (
                                <p>
                                  <Sanitized html={t('page.results.dropOffMessage')} />
                                  <a href="" onClick={handleDropOff}>
                                    {t('page.results.dropOffLinkText')}
                                  </a>
                                </p>
                              ) : (
                                <p>
                                  {t('page.results.onSiteMessage')}
                                  <a href="" onClick={handleOnSite}>
                                    {t('page.results.onSiteLinkText')}
                                  </a>
                                </p>
                              )}
                            </Banner>
                          )}
                          <div className={`store-results${activeStore && ipadSize ? ' ipad-down' : ''}`}>
                            <div className="store-list-container">
                              <Stores role="list" id="store-results-list" aria-label="Store results" className="store-list">
                                {filteredStores && filteredStores.length === 0 && <NoMatch onReset={filterReset} />}
                                {filteredStores.map((store) => (
                                  <StoreInfo
                                    key={store.id}
                                    role="listitem"
                                    apptSchedulerIndVal={store.apptSchedulerIndVal}
                                    country={country}
                                    channel={channel}
                                    casRedirect={global.casRedirect}
                                    orderNumber={store.orderNumber}
                                    location={{ latitude: store.latitude, longitude: store.longitude }}
                                    title={store.title}
                                    badge={store.storeBadges}
                                    addressLine1={[store.street1, store.street2].filter(Boolean).join(', ')}
                                    isRetailStore={store.storeTypes?.includes(5) || false}
                                    addressLine2={[store.city, store.state, store.postalCode].filter(Boolean).join(', ')}
                                    products={channel === 'sales' ? store.salesProducts : store.serviceProducts}
                                    distance={store.distance}
                                    onClick={handleSelection}
                                    onHover={handleHover}
                                    active={active === store.orderNumber}
                                    focus={focus === store.orderNumber}
                                  />
                                ))}
                              </Stores>
                              <Pagination
                                totalRecords={stores.length}
                                currentPage={currentPage}
                                pageLimit={PAGE_LIMIT}
                                onPageChanged={onPageChanged}
                                locale={selectedLocale}
                              />
                            </div>
                            {!ipadSize && (
                              <div className="store-map">
                                {filteredStores && filteredStores.length === 0 && (
                                  <Map center={{ latitude: Number(query.latitude), longitude: Number(query.longitude) }}></Map>
                                )}
                                {filteredStores && filteredStores.length > 0 && (
                                  <Map
                                    center={{ latitude: filteredStores[0].latitude, longitude: filteredStores[0].longitude }}
                                    adjustMap={adjustMap}>
                                    {filteredStores.map((store) => (
                                      <Marker
                                        key={store.id}
                                        selected={active === store.orderNumber}
                                        hover={highlightStore === store.orderNumber}
                                        coordinate={createCoordinate(store.latitude, store.longitude)}
                                        glyphText={store.orderNumber.toString()}
                                        srLabel={`${t('infoWindow.pinAltText')} ${store.orderNumber}. ${store.title} ${
                                          store.street1 + store.street2
                                        }`}
                                        color="#0066CC"
                                        glyphColor="#FFFFFF"
                                        store={store}>
                                        <AddressCard
                                          directions={directionSearch}
                                          getDirection={global.getDirection}
                                          disableMap={global.disableMap}
                                          source={query}
                                          country={country}
                                          channel={channel}
                                          locale={selectedLocale}
                                          mode={results.modesOfTransport}
                                          casSupportUrl={global.casSupportUrl}
                                          selectedProduct={query.productId}
                                          isRetailStore={store.storeTypes?.includes(5) || false}
                                          casRedirect={global.casRedirect}
                                          badges={store.storeBadges}
                                          products={channel === 'sales' ? store.salesProducts : store.serviceProducts || []}
                                          totalStores={stores.length}
                                          address={query.formattedAddress}
                                          closeOnEsc={handleCloseOnEscape}
                                          backToList={handleFocusToList}
                                          {...store}
                                        />
                                      </Marker>
                                    ))}
                                  </Map>
                                )}
                              </div>
                            )}
                          </div>
                          {activeStore && ipadSize && (
                            <StoreDetail
                              getDirection={global.getDirection}
                              disableMap={global.disableMap}
                              source={query}
                              country={country}
                              channel={channel}
                              locale={selectedLocale}
                              mode={results.modesOfTransport}
                              casSupportUrl={global.casSupportUrl}
                              selectedProduct={query.productId}
                              casRedirect={global.casRedirect}
                              products={channel === 'sales' ? activeStore.salesProducts : activeStore.serviceProducts || []}
                              totalStores={stores.length}
                              address={query.formattedAddress}
                              directions={directionSearch}
                              store={activeStore}
                            />
                          )}
                          {badges && badges.length > 0 && (
                            <div className={`store-badges${badges.length > 2 ? ' badges-3' : ` badges-${badges.length}`}`}>
                              {badges.map((badge) => (
                                <BadgeInfo key={badge.id} {...badge} />
                              ))}
                            </div>
                          )}
                        </Fragment>
                      )}
                      {results && results.stores.length === 0 && !fetching_results && (
                        <Fragment>
                          <div className="seperator"></div>
                          <NoResult channel={channel} ref={noResultRef} />
                        </Fragment>
                      )}
                      {fetching_results && (
                        <Fragment>
                          <div className="seperator"></div>
                          <div className="results-loading" ref={loaderRef}>
                            <Loader ariaLabel="Loading store results" />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}
            </Layout>
          </ErrorBoundary>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ResultsPage;
