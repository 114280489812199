import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EVENT_MAP } from '../../constants';
import { Widget } from '../../models';
import { trackEvent } from '../../services/utility';
import { Sanitized } from '@idms/react-sanitized';
import Icon from '../icon';
import Image from '../image';
import './tile.scss';

export interface TileProps extends Widget {
  rounded?: boolean;
  shadow?: boolean;
  className?: string;
  floatingButton?: boolean;
  icon?: string;
  image: string;
  imagePosition?: 'left' | 'right';
  locale?: string;
  path: string;
  channel?: string;
  children?: React.ReactNode;
}

const Tile = ({
  rounded = false,
  shadow = false,
  imagePosition = 'left',
  image,
  channel,
  className = '',
  path,
  externalLink = '',
  locale = '',
}: TileProps): JSX.Element => {
  const classes = ['card card-grid', rounded ? 'card-rounded' : '', shadow ? 'card-shadow' : '', className].filter(Boolean).join(' ');
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    trackEvent(EVENT_MAP.CLICK, {
      link_owner: 'ac_link',
      name: channel,
      type: 'simple link',
      link_text: event.target.innerText,
      dest_url: event.target.href,
    });
  };

  return (
    <div className={classes}>
      {imagePosition === 'left' && <figure className={`pattern-image ${channel}-img`}>{channel && <Image name={channel} />}</figure>}
      <div className="card-content">
        <Sanitized tagName="h2" className="typography-headline-reduced" html={t(`${channel}.widget.title`)} />
        <Sanitized tagName="p" className="typography-body" html={t(`${channel}.widget.description`)} />
        <div className="widget-view">
          <p className="typography-body">
            {externalLink ? (
              <a onClick={handleClick} href={externalLink}>
                <span className="icon-copy">
                  {t(`${channel}.widget.salesLink`)}
                  <Icon
                    as="icon"
                    href={externalLink}
                    label={t(`${channel}.widget.salesLink`)}
                    name={locale.startsWith('ar-') ? 'chevronleft' : 'chevronright'}
                    after
                  />
                </span>
              </a>
            ) : (
              <Link
                onClick={handleClick}
                to={path === '/' ? `/${channel}` : path.endsWith('/') ? `${path}${channel}` : `${path}/${channel}`}>
                {t(`${channel}.widget.salesLink`)}
                <Icon
                  as="icon"
                  href={externalLink}
                  label={t(`${channel}.widget.salesLink`)}
                  name={locale.startsWith('ar-') ? 'chevronleft' : 'chevronright'}
                  after
                />
              </Link>
            )}
          </p>
        </div>
      </div>
      {imagePosition === 'right' && <figure className={`pattern-image ${channel}-img`}>{channel && <Image name={image} />}</figure>}
    </div>
  );
};
export default Tile;
