export const ORDERED_CHANNEL_ARRAY = ['sales', 'service', 'consulting'];
export const CHANNELS = ['sales', 'service'];
export const OTHER_COUNTRIES = ['asia', 'europe', 'amei', 'lac'];
export const DIRECTIONS = ['automobile', 'walking'];
export const MAP_COUNTRY: { [key: string]: string } = {
  asia: 'Other Asia',
  europe: 'Other Europe',
  amei: 'Other Africa and Middle East',
  lac: 'Latin America and the Caribbean',
};

export const CHINESE_LANG_COUNTRIES = ['cn', 'mo', 'hk', 'tw'];
export const NOTICE_EXCEPTIONS = ['BD', 'BN', 'KH', 'CN', 'GU', 'LA', 'MV', 'MN', 'MM', 'NP', 'PC', 'PK', 'LK'];
export const GEOCODESTATUS = ['BULK_UPLOAD', 'DATA_FEED', 'ADDRESS_PARCEL', 'ADDRESS_INTERPOLATED'];
export const BADGE_MAP: { [key: string]: string } = {
  ashop: 'Apple Shop',
  ars: 'Apple Store',
  as: 'Specialist',
  cs: 'Coming Soon',
  apr: 'Premium',
  premier: 'Premier',
  aw: 'Apple Watch',
  do: 'Dropoff',
};
export const SOLUTION_SPECIALITIES = ['ss1', 'ss2', 'ss3', 'ss4', 'ss5', 'ss6'];
export enum EVENT_MAP {
  PAGE_LOAD = 'loc.page',
  SEARCH = 'loc.search',
  CLICK = 'loc.link',
}
export const CAS_APP_URL = 'https://getsupport-itc.apple.com?caller=grl&locale=';
