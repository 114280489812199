import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Sanitized } from '@idms/react-sanitized';
import DOMPurify from 'dompurify';
import './summary.scss';

interface SummaryProps {
  displayCount: number;
  channel: string;
  product: string;
  address: string;
  dropoff?: boolean;
}

const Summary = ({ displayCount, channel, product = '', address, dropoff = false }: SummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const sanitizedAddress = DOMPurify.sanitize(address, { ALLOWED_TAGS: [] });
  return (
    <Fragment>
      {product && product.toLowerCase() === 'all products' ? (
        <Sanitized
          tagName="p"
          className="search-text"
          aria-live="polite"
          html={t(`${channel}.results.allsentence`, { count: displayCount, location: sanitizedAddress })}
        />
      ) : dropoff ? (
        <Sanitized
          tagName="p"
          className="search-text"
          aria-live="polite"
          html={t(`${channel}.results.dropoffsentence`, { count: displayCount, product, location: sanitizedAddress })}
        />
      ) : (
        <Sanitized
          tagName="p"
          className="search-text"
          aria-live="polite"
          html={t(`${channel}.results.sentence`, { count: displayCount, product, location: sanitizedAddress })}
        />
      )}
    </Fragment>
  );
};

export default memo(Summary);
