import React, { memo } from 'react';
import ConsultingImg from '../../assets/consultants.jpg';
import SalesImg from '../../assets/sales.jpg';
import SalesImg_2x from '../../assets/sales_2x.png';
import ServiceImg from '../../assets/service.jpg';
import ServiceImg_2x from '../../assets/service_2x.png';
import RetailImg from '../../assets/store-img.png';
import RetailImg_2x from '../../assets/store-img_2x.png';

const Image = ({ name, width, height }: { name: string; width?: number; height?: number }): JSX.Element => {
  const isRetinaDisplay = window.devicePixelRatio >= 2;
  switch (name) {
    case 'sales':
      return <img src={isRetinaDisplay ? SalesImg_2x : SalesImg} aria-hidden alt="sales image" width={width} height={height} />;
    case 'retail':
      return <img src={isRetinaDisplay ? RetailImg_2x : RetailImg} aria-hidden alt="retail image" width={width} height={height} />;
    case 'service':
      return <img src={isRetinaDisplay ? ServiceImg_2x : ServiceImg} aria-hidden alt="service image" width={width} height={height} />;
    case 'consulting':
      return <img src={ConsultingImg} aria-hidden alt="consulting image" width={width} height={height} />;
    default:
      return <></>;
  }
};

export default memo(Image);
